<template>
  <div class="bg-light-green w-full rounded-lg mt-2 xl:mt-20 relative">
    <div class="py-10 px-5 px-base">
      <NuxtLink
        to="https://uk.trustpilot.com/review/justhire.com"
        target="_blank"
        rel="noopener noreferrer"
        loading="lazy"
      >
        <p class="font-primary text-black font-bold text-2xl">Excellent</p>
        <NuxtImg
          provider="storyblok"
          class="w-36 absolute right-4 top-4"
          :src="TRUSTPILOT_LOGO_URL"
          alt="Trustpilot Logo"
          loading="lazy"
          format="webp"
        />
        <NuxtImg
          provider="storyblok"
          class="w-44 my-4"
          :src="TRUSTPILOT_STARS_URL"
          alt="Trustpilot Stars"
          loading="lazy"
          format="webp"
        />
        Based on <span class="font-semibold underline">300+ reviews</span>
      </NuxtLink>
    </div>
  </div>
</template>
<script setup lang="ts">
const TRUSTPILOT_LOGO_URL = "f/237253/320x132/6d8f347146/trustpilot-logo.png";
const TRUSTPILOT_STARS_URL = "/f/237253/512x96/e1a421405b/trust-stars.png";
</script>
